<template>
  <TagbyActionButton
    variant="outline-primary"
    text="그룹 정보 저장"
    :isSaving="isSaving"
    @click="update"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import {
  useUpdateDefault,
} from '../viewModel'
import TagbyActionButton from '@/components/TagbyActionButton.vue'

export default defineComponent({
  components: {
    BButton,
    BSpinner,
    TagbyActionButton,
  },
  setup() {
    const {
      update,
      isSaving,
    } = useUpdateDefault()

    return {
      update,
      isSaving,
    }
  },
})
</script>
