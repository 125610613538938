<template>
  <TagbyDetailLayout>
    <template #error>
      <TagbyLoadError
        :hasLoadError="hasLoadError"
        subject="banner"
        :to="{ name: 'app-widget-banner-list'}"
      />
    </template>
    <template #content>
      <AppWidgetInfoCard />
      <ContentCard />
    </template>
    <template #side>
      <ActionCard />
      <TagbyTimeline :timelineData="timelines" />
    </template>
  </TagbyDetailLayout>
</template>

<script>
import {
} from 'bootstrap-vue'
import {
  useInitialize,
  useDetail,
  useTimelines,
} from './viewModel'

import TagbyDetailLayout from '@/components/TagbyDetailLayout.vue'
import TagbyLoadError from '@/components/TagbyLoadError.vue'
import TagbyTimeline from '@/components/TagbyTimeline.vue'

import AppWidgetInfoCard from './components/AppWidgetInfoCard.vue'
import ContentCard from './components/ContentCard.vue'
import ActionCard from './components/ActionCard.vue'
// import ItemGroupTable from './components/ItemGroupTable.vue'
// import ItemAddSidebar from './components/ItemAddSidebar.vue'
// import ItemRemoveModal from './components/ItemRemoveModal.vue'

export default {
  components: {
    TagbyDetailLayout,
    TagbyLoadError,
    TagbyTimeline,

    AppWidgetInfoCard,
    ContentCard,
    ActionCard,
    // ItemGroupTable,
    // ItemAddSidebar,
    // ItemRemoveModal,
  },
  setup() {
    const { initialize } = useInitialize()
    initialize()

    const {
      hasLoadError,
      fetchDetail,
    } = useDetail()
    fetchDetail()

    const {
      timelines,
      fetchTimelines,
    } = useTimelines()
    fetchTimelines()

    return {
      hasLoadError,
      timelines,
    }
  },
}
</script>
