import {
  ref,
  computed,
  onUnmounted,
} from '@vue/composition-api'

import store from '@/store'
import router from '@/router'
import useToast from '@/utils/toast'
import useStore from '@/utils/store'
import useResolveValue from '@/utils/resolveValue'
import uploadFile from '@/utils/uploadFile'

import appWidgetStoreModule from '@/store/tagby/appWidget'

const paramIdx = ref()

export function useInitialize() {
  const initialize = () => {
    paramIdx.value = router.currentRoute.params.idx

    if (!store.hasModule(appWidgetStoreModule.STORE_MODULE_NAME)) {
      store.registerModule(appWidgetStoreModule.STORE_MODULE_NAME, appWidgetStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(appWidgetStoreModule.STORE_MODULE_NAME)) {
        store.unregisterModule(appWidgetStoreModule.STORE_MODULE_NAME)
      }
    })
  }
  return {
    initialize,
  }
}

const appWidget = ref({})
const appWidgetContent = ref()
const hasLoadError = ref(false)
export function useDetail() {
  const { dispatch } = useStore(appWidgetStoreModule.STORE_MODULE_NAME)
  const stateOptions = [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'INACTIVE', value: 'INACTIVE' },
  ]
  const typeOptions = [
    { label: 'HOME', value: 'HOME' },
    { label: 'MARKET', value: 'MARKET' },
    { label: 'RECOMMEND_CAMPAIGN', value: 'RECOMMEND_CAMPAIGN' },
  ]

  const fetchDetail = () => dispatch('fetchDetail', {
    idx: paramIdx.value,
  }).then(response => {
    appWidget.value = response.data.data
    appWidgetContent.value = JSON.stringify(appWidget.value.content, null, 4)
  }).catch(() => {
    hasLoadError.value = true
  }).finally(() => {
  })

  return {
    appWidget,
    hasLoadError,
    stateOptions,
    typeOptions,
    fetchDetail,
  }
}

const timelines = ref()
export function useTimelines() {
  const { makeToast } = useToast()
  const { dispatch } = useStore(appWidgetStoreModule.STORE_MODULE_NAME)
  const fetchTimelines = () => {
    dispatch('fetchTimelines', {
      idx: paramIdx.value,
    }).then(response => {
      timelines.value = response.data.data
    }).catch(() => {
      makeToast('danger', '타임라인을 가져오는데 실패했습니다')
    }).finally(() => {
    })
  }

  return {
    timelines,
    fetchTimelines,
  }
}

export function useAppWidgetContent() {
  const { makeToast } = useToast()
  const contentType = computed(() => {
    const tableName = appWidget.value.content?.table_name
    if (tableName === 'campaign_info') {
      return 'CAMPAIGN_LIST'
    }
    if (tableName === 'product_info') {
      return 'PRODUCT_LIST'
    }
    return 'SIMPLE'
  })
  const contentTypeOptions = [
    { label: 'SIMPLE', value: 'SIMPLE' },
    { label: 'PRODUCT_LIST', value: 'PRODUCT_LIST' },
    { label: 'CAMPAIGN_LIST', value: 'CAMPAIGN_LIST' },
  ]

  const inputContentType = value => {
    if (value === 'SIMPLE') {
      appWidget.value.content = {
        title_text: '',
        link_text: '',
        thumb_url: '',
        route: {
          route_name: ['CampaignList'],
          params: [{
            api_endpoint: '/app-widget/recommend-campaign-list',
          }],
        },
      }
    } else if (value === 'PRODUCT_LIST') {
      appWidget.value.content = {
        table_name: 'product_info',
        idx_list: [],
      }
    } else if (value === 'CAMPAIGN_LIST') {
      appWidget.value.content = {
        table_name: 'campaign_info',
        idx_list: [],
      }
    }
    appWidgetContent.value = JSON.stringify(appWidget.value.content, null, 4)
  }

  const isValid = computed(() => {
    try {
      JSON.parse(appWidgetContent.value)
      return true
    } catch {
      return false
    }
  })

  const blurAppWidgetContent = () => {
    if (isValid.value) {
      appWidget.value.content = JSON.parse(appWidgetContent.value)
    } else {
      makeToast('danger', '유효하지 않은 내용값입니다')
    }
  }

  const file = ref()
  async function inputFile(value) {
    const fileInfo = await uploadFile(value, 'app_widget_info')
    appWidget.value.content.thumb_url = fileInfo.file_url
    appWidgetContent.value = JSON.stringify(appWidget.value.content, null, 4)
  }

  return {
    contentType,
    contentTypeOptions,
    inputContentType,
    isValid,
    appWidgetContent,
    blurAppWidgetContent,
    file,
    inputFile,
  }
}

const productItems = ref([])
export function useProductItems() {

}

const campaignItems = ref([])
export function useCampaignItems() {

}

export function useUpdateDefault() {
  const { makeToast } = useToast()
  const { dispatch } = useStore(appWidgetStoreModule.STORE_MODULE_NAME)
  const { fetchDetail } = useDetail()
  const { fetchTimelines } = useTimelines()
  const { resolveUtcDateTime } = useResolveValue()

  const isSaving = ref(false)
  const update = () => {
    isSaving.value = true
    const params = {
      idx: paramIdx.value,
      name: appWidget.value.name,
      start_at: resolveUtcDateTime(appWidget.value.start_at),
      end_at: resolveUtcDateTime(appWidget.value.end_at),
      state: appWidget.value.state,
      type: appWidget.value.type,
      content: appWidget.value.content,
    }

    dispatch('update', params)
      .then(() => {
        fetchDetail()
        fetchTimelines()
        makeToast('primary', '저장에 성공했습니다.')
      }).catch(() => {
        makeToast('danger', '저장에 실패했습니다.')
      }).finally(() => {
        isSaving.value = false
      })
  }

  return {
    isSaving,
    update,
  }
}

export function useMemo() {
  const { makeToast } = useToast()
  const { dispatch } = useStore(appWidgetStoreModule.STORE_MODULE_NAME)
  const { fetchTimelines } = useTimelines()
  const memoContent = ref()
  const visibleMemo = ref(false)
  const turnOnMemo = () => {
    visibleMemo.value = true
    memoContent.value = null
  }
  const turnOffMemo = () => {
    visibleMemo.value = false
  }
  const isSavingMemo = ref(false)
  const inputMemoContent = value => {
    memoContent.value = value
  }
  const createMemo = () => {
    isSavingMemo.value = true
    dispatch('createMemo', {
      idx: paramIdx.value,
      content: memoContent.value,
    }).then(() => {
      makeToast('primary', '메모 생성에 성공했습니다')
      fetchTimelines()
    }).catch(() => {
      makeToast('primary', '메모 생성에 성공했습니다')
    }).finally(() => {
      isSavingMemo.value = false
      turnOffMemo()
    })
  }

  return {
    memoContent,
    visibleMemo,
    turnOnMemo,
    turnOffMemo,
    isSavingMemo,
    inputMemoContent,
    createMemo,
  }
}
