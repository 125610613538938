<template>
  <TagbyActionCard>
    <template #left>
      <UpdateButton />
    </template>
  </TagbyActionCard>
</template>

<script>
import {
} from 'bootstrap-vue'
import TagbyActionCard from '@/components/TagbyActionCard.vue'
import UpdateButton from './UpdateButton.vue'

export default {
  components: {
    TagbyActionCard,
    UpdateButton,
  },
}
</script>
